<template>
  <section class="mainText ">
    <b-container fluid="xl">
        <b-row id="main1" class="maintText__row">
            <b-col cols="12" class="pb-5 side-gap">
                <h2 style="mainText__mainName--maintxtx">
                    Cyberbullying Kutatás
                </h2>
            </b-col>
        </b-row>
        <b-row class="maintText__row">
            <b-col cols="12" lg="10" class="side-gap offset-lg-1">
            <div class="maintText__txt-content pb-lg-5 pb-2">
                <p class="text">
                Az Opinio Institute Kft.-vel közös felmérésünkben arra kerestük a választ, hogy az internetes felhasználókat milyen arányban és formában érinti az online bántalmazás, és ez miként hat rájuk. *
                </p>
            </div>
            </b-col>
            <b-col cols="12" lg="8" class="side-gap text-center offset-lg-2">
                <img src="@/assets/images/bgr/infografika.jpg" class="w-100 margin-top-sm-44"/>
            </b-col>
        </b-row>
    </b-container>
    <Articles :isFirst="false"/>
  </section>
</template>
<script>
import Articles from '../components/Articles.vue'

export default {
  components: {
    Articles
  },
  mounted(){

    this.setMetaTags({        
        title: "Cyberbullying Kutatás", 
        desc: "Az Opinio Institute Kft.-vel közös felmérésünkben arra kerestük a választ, hogy az internetes felhasználókat milyen arányban és formában érinti az online bántalmazás, és ez miként hat rájuk.", 
        keywords: null, 
        ogType: 'website', 
        ogTitle: "Cyberbullying Kutatás",
        ogImage: null, 
        ogImageWidth: null, 
        ogImageHeight: null, 
        ogDesc: "Az Opinio Institute Kft.-vel közös felmérésünkben arra kerestük a választ, hogy az internetes felhasználókat milyen arányban és formában érinti az online bántalmazás, és ez miként hat rájuk."        
    })
  }
}
</script>